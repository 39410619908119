
import './App.css';
import React, { useEffect} from "react";
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Doctors from './components/pages/Doctors';
import Findatest from './components/pages/Findatest';
import Contact from './components/pages/Contact';
import Radiology from './components/pages/Radiology';
import Xray from './components/pages/Xray';
import Usg from './components/pages/Usg';
import Ctscan from './components/pages/Ctscan';
import Mri from './components/pages/Mri';
import Opg from './components/pages/Opg';
import Sample from './components/pages/Sample';
import Cardiology from './components/pages/Cardiology';
import Neurology from './components/pages/Neurology';
import Terms from './components/pages/Terms';
import Feedbackpage from './components/pages/Feedbackpage';
import Healthpage from './components/pages/Healthpage';
import DocAlcohol from './components/pages/DocAlcohol';
import DocGeneral from './components/pages/DocGeneral';



function App() {
  return (
    <Router>
      
  
     
    <div>
  
     
      
      <Routes>
 
     
      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/find-doctor" element={<Doctors/>}/>
      <Route axact path="/labtest" element={<Findatest/>}/>
      <Route axact path="/contact-us" element={<Contact/>}/>
      <Route axact path="/Radiology" element={<Radiology/>}/>
      <Route axact path="/xray" element={<Xray/>}/>
      <Route axact path="/Usg" element={<Usg/>}/>
      <Route axact path="/ct-scan" element={<Ctscan/>}/>
      <Route axact path="/mri" element={<Mri/>}/>
      <Route axact path="/opg" element={<Opg/>}/>
      <Route axact path="/home-sample-colllection" element={<Sample/>}/>
      <Route axact path="/Cardiology" element={<Cardiology/>}/>
      <Route axact path="/Neurology" element={<Neurology/>}/>
      <Route axact path="/Terms" element={<Terms/>}/>
      <Route axact path="/Feedback-page" element={<Feedbackpage/>}/>
      <Route axact path="/health-package" element={<Healthpage/>}/>

      <Route path="/besthealth/:docId" element={<DocAlcohol/>}/>
      <Route path="/healthpackage/:docId" element={<DocGeneral/>}/>
                   
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;
